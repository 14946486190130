<template>
  <div class="container">
    <card class="login-card">
      <form @submit.prevent="login">
        <base-input type="text" label="Логин" placeholder="login" v-model="user.login" autocapitalize="none">
        </base-input>
        <base-input type="password" label="Пароль" placeholder="password" v-model="user.password">
        </base-input>
        <base-button slot="footer" type="submit" nativeType="submit" block :disabled="loading">Вход</base-button>
        <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </form>
    </card>
  </div>
</template>


<script>
import User from '../models/user';

export default {
  name: 'Login',
  data() {
    return {
      user: new User(null, '', '', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/dashboard');
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$store.dispatch('auth/login', this.user).then(
        () => {
          this.$router.push('/integrations');
        },
        error => {
          this.loading = false;
          let alert_1 = document.querySelector("#alert_1");
          alert_1.classList.remove("d-none");
          alert_1.innerHTML = "Ошибка входа";
        }
      );
    }
  }
};
</script>

<style>
.container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 400px;
  max-width: 95%
}

.login-card {
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
</style>
